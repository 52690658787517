import React from "react";
import { useBreakPointDown, useBreakPointUp } from "blace-frontend-library";
import { ReactSortable } from "react-sortablejs";

interface AnyObjectWithId {
  id: string | number;
  [key: string]: unknown;
}

interface ReactSortableManagerProps<T extends AnyObjectWithId> {
  list: T[];
  setList: (newState: T[]) => void;
  markHasUnsavedData: () => void;
  className?: string;
  children: React.ReactNode;
}

function ReactSortableManager<T extends AnyObjectWithId>({
  list,
  setList,
  markHasUnsavedData,
  className,
  children,
}: ReactSortableManagerProps<T>) {
  const isMobile = useBreakPointDown("md");
  const isDesktop = useBreakPointUp("md");

  if (isMobile) {
    return (
      <ReactSortable
        list={list}
        setList={setList}
        className={className}
        handle=".dots-handle"
        scrollSensitivity={100}
        forceAutoScrollFallback={isMobile}
        scroll={true}
        onUpdate={markHasUnsavedData}
      >
        {children}
      </ReactSortable>
    );
  }
  if (isDesktop) {
    return (
      <ReactSortable
        list={list}
        setList={setList}
        className={className}
        filter=".exclude"
        onUpdate={markHasUnsavedData}
      >
        {children}
      </ReactSortable>
    );
  }

  return <></>;
}

export default ReactSortableManager;
