import { BlaceV2Type } from "@/src//type";

export enum ListingCategories {
  Details = "details",
  Photos = "photos",
  Pricing = "pricing",
  Rooms = "rooms",
  Tags = "tags",
  Contacts = "contacts",
  Vendors = "vendors"
}

export enum StatusChangeToastText {
  Published = "Listing was successfully published",
  Unpublished = "Listing was successfully deactivated",
}

export interface CategoryItem {
  id: number;
  category: ListingCategories;
  completed: boolean;
  dataTypes: BlaceV2Type.SearchType.SearchDataType[];
}

export const SELECTED_CATEGORY_PARAM = "category";

export const INITIAL_CATEGORIES_LIST: CategoryItem[] = [
  {
    id: 1,
    category: ListingCategories.Details,
    completed: false,
    dataTypes: [BlaceV2Type.SearchDataTypes.Venue, BlaceV2Type.SearchDataTypes.Vendor],
  },
  {
    id: 2,
    category: ListingCategories.Photos,
    completed: false,
    dataTypes: [BlaceV2Type.SearchDataTypes.Venue, BlaceV2Type.SearchDataTypes.Vendor],
  },
  {
    id: 3,
    category: ListingCategories.Pricing,
    completed: false,
    dataTypes: [BlaceV2Type.SearchDataTypes.Venue, BlaceV2Type.SearchDataTypes.Vendor],
  },
  {
    id: 4,
    category: ListingCategories.Rooms,
    completed: false,
    dataTypes: [BlaceV2Type.SearchDataTypes.Venue],
  },
  {
    id: 5,
    category: ListingCategories.Tags,
    completed: false,
    dataTypes: [BlaceV2Type.SearchDataTypes.Venue, BlaceV2Type.SearchDataTypes.Vendor],
  },
  {
    id: 6,
    category: ListingCategories.Contacts,
    completed: false,
    dataTypes: [BlaceV2Type.SearchDataTypes.Venue, BlaceV2Type.SearchDataTypes.Vendor],
  },
  {
    id: 7,
    category: ListingCategories.Vendors,
    completed: false,
    dataTypes: [BlaceV2Type.SearchDataTypes.Venue],
  },
];
