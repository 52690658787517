import { SyntheticEvent, useState } from "react";
import { Autocomplete, Collapse, TextField, Typography } from "@mui/material";
import { BaseButton, BaseIcon, BaseLoader, useBreakPointDown } from "blace-frontend-library";
import cn from "classnames";
import {
  VendorCardData,
  VendorSuggestion,
} from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/LinkedVendorsForm/LinkedVendorsForm";
import { LinkedVendorsList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/LinkedVendorsList";
import styles from "./VendorSection.module.scss";

interface VendorSectionProps {
  title: string;
  description: string;
  handleAddVendor: (event: SyntheticEvent<Element, Event>, value: VendorSuggestion | null) => void;
  vendors: VendorCardData[];
  vendorOptions: VendorSuggestion[];
  inputId: string;
  searchVendorValue: string;
  isVendorOptionsLoading: boolean;
  setSearchVendorValue: (value: string) => void;
  setVendors: (vendors: VendorCardData[]) => void;
}

const VendorSection = ({
  title,
  description,
  searchVendorValue,
  setSearchVendorValue,
  handleAddVendor,
  vendors,
  vendorOptions,
  isVendorOptionsLoading,
  inputId,
  setVendors,
}: VendorSectionProps) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  const isMobile = useBreakPointDown("md");
  const withoutNoMatchesText =
    !vendorOptions.length && (searchVendorValue.length <= 1 || isVendorOptionsLoading);

  return (
    <>
      {isMobile && (
        <BaseButton
          className={styles.collapseButton}
          startIcon={
            <BaseIcon
              iconAlt={isMobileOpen ? "close" : "open"}
              iconFileName={isMobileOpen ? "minusIcon" : "plusCircleIcon"}
            />
          }
          onClick={() => setIsMobileOpen((prev) => !prev)}
        >
          {title}
        </BaseButton>
      )}
      <Collapse in={!isMobile || isMobileOpen} className={styles.formSection}>
        <div>
          <Typography className={styles.formSectionTitle}>{title}</Typography>
          <Typography className={styles.formSectionSubTitle}>{description}</Typography>
          <div
            className={cn(styles.formItem, {
              [styles.noAutocompleteOptions]: withoutNoMatchesText,
            })}
          >
            <label className={cn(styles.inputLabel)}>Choose vendors</label>
            <Autocomplete
              disablePortal
              id={inputId}
              options={vendorOptions}
              getOptionLabel={(option) => option?.title}
              fullWidth
              loading={isVendorOptionsLoading}
              open={isAutocompleteOpen}
              onOpen={() => setIsAutocompleteOpen(true)}
              onClose={() => setIsAutocompleteOpen(false)}
              value={null}
              filterOptions={(x) => x}
              onChange={handleAddVendor}
              className={styles.formAutocomplete}
              blurOnSelect
              noOptionsText="No matching vendors were found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={searchVendorValue}
                  placeholder={`Add ${title.toLowerCase()}`}
                  onChange={(e) => setSearchVendorValue(e.target.value)}
                  className={styles.textField}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment:
                      isVendorOptionsLoading && isAutocompleteOpen ? (
                        <BaseLoader wrapperClassName={styles.inputProgress} size={20} />
                      ) : null,
                  }}
                />
              )}
            />
          </div>
        </div>
        <LinkedVendorsList vendors={vendors} setVendors={setVendors} />
      </Collapse>
    </>
  );
};

export default VendorSection;
