import { useMemo } from "react";
import { FILTERS, Log, SearchLogic } from "blace-frontend-library";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { InputList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/PriceContent/components/PricingForm/PricingForm";
import { FormLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { PriceDurationBE } from "@/src/type/app";
import { SearchPrice, SearchPriceV2 } from "@/src/type/blaceV2/search/SearchType";

interface PriceSaveData {
  price: Partial<SearchPriceV2>;
}

interface UsePricingFormProps {
  pricingData?: Partial<SearchPriceV2 & SearchPrice>;
  priceSaveHandler?: (data: PriceSaveData) => Promise<Record<string, string>>;
  dataType?: BlaceV2Type.SearchType.SearchDataType;
}

export interface PricingFormType {
  [InputList.PricingDetails]: string;
  [InputList.ShowPricing]: boolean;
  [InputList.Price]: string;
  [InputList.PriceDuration]: string;
  [InputList.PricingLevel]: string;
  [InputList.CancellationPolicy]: string;
}

export function usePricingForm({ pricingData, priceSaveHandler, dataType }: UsePricingFormProps): {
  formik: FormikProps<PricingFormType>;
} {
  const defaultPricingDetails = pricingData?.details ?? "";

  const defaultCancellationPolicy = pricingData?.cancellationPolicy ?? "";

  const isV1Version = pricingData?.showPricing === undefined || pricingData.showPricing === null;

  const defaultShowPricing =
    (isV1Version ? pricingData?.displayPrice : pricingData?.showPricing) ?? true;

  const pricing = isV1Version
    ? pricingData?.minimumStartingPrice
    : pricingData?.pricingValueInCents
      ? pricingData?.pricingValueInCents / 100
      : undefined;
  const defaultPrice = pricing?.toString() ?? "";

  const defaultPriceDuration =
    (isV1Version
      ? SearchLogic.convertPricingDurationToBackend(pricingData?.priceUnit)
      : pricingData?.pricingDuration) ?? PriceDurationBE.PerDay;

  const defaultPricingLevel = pricingData?.priceLevel?.toString() ?? "";

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [InputList.PricingDetails]: Yup.string()
          .notRequired()
          .max(1500, "Please enter details with a character count between 0 and 1500"),
        [InputList.ShowPricing]: Yup.boolean(),
        [InputList.Price]: Yup.number().typeError("Please enter a number"),
        [InputList.PriceDuration]: Yup.mixed().oneOf(Object.values(PriceDurationBE)),
        [InputList.PricingLevel]: Yup.mixed().oneOf(
          FILTERS.vendorPricing.options.map(({ value }: { value: string }) => value),
        ),
        [InputList.CancellationPolicy]: Yup.string()
          .notRequired()
          .max(1500, "Please enter a cancellation policy with a character count between 0 and 1500"),
      }),
    [],
  );

  const formik = useFormik<PricingFormType>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      [InputList.PricingDetails]: defaultPricingDetails,
      [InputList.ShowPricing]: defaultShowPricing,
      [InputList.Price]: defaultPrice,
      [InputList.PriceDuration]: defaultPriceDuration,
      [InputList.PricingLevel]: defaultPricingLevel,
      [InputList.CancellationPolicy]: defaultCancellationPolicy,
    },
    validationSchema,
    onSubmit: async (values, { setFieldError, setFieldTouched }) => {
      let priceFormData;

      if (dataType === BlaceV2Type.SearchDataTypes.Venue) {
        priceFormData = {
          details: Boolean(values[InputList.PricingDetails])
            ? values[InputList.PricingDetails]
            : null,
          showPricing: Boolean(values[InputList.ShowPricing]),
          pricingValueInCents: Boolean(values[InputList.Price])
            ? +values[InputList.Price] * 100
            : null,
          pricingDuration: Boolean(values[InputList.PriceDuration])
            ? values[InputList.PriceDuration]
            : null,
          cancellationPolicy: Boolean(values[InputList.CancellationPolicy]) 
            ? values[InputList.CancellationPolicy]
            : null,
        };
      }

      if (dataType === BlaceV2Type.SearchDataTypes.Vendor) {
        priceFormData = {
          priceLevel: Boolean(values[InputList.PricingLevel])
            ? values[InputList.PricingLevel]
            : null,
        };
      }
      
      const formValuesData = {
        price: priceFormData || {},
      };

      // handle backend validation
      const unknownFields: string[] = [];
      const errors = priceSaveHandler ? await priceSaveHandler(formValuesData) : {};

      FormLogic.handleServerErrors(errors, values, setFieldError, unknownFields);

      // handle a case when backend returned non known fields
      if (Boolean(unknownFields.length)) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "usePricingForm.tsx",
          "onSubmitError",
          [unknownFields],
          "Unknown fields were returned from the server with errors.",
        );
        setFieldTouched(InputList.PricingDetails, true, false);
      }
    },
  });

  return {
    formik,
  };
}
