import {
  forwardRef,
  SyntheticEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { SearchLogic, SearchServiceV2 } from "blace-frontend-library";
import { useDebouncedCallback } from "use-debounce";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { VendorSection } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/VendorSection";
import { useLinkedVendorsForm } from "@/src/hook";
import { App } from "@/src/type";
import styles from "./LinkedVendorsForm.module.scss";

interface LinkedVendorsFormProps {
  onSaveVendorsFormData?: () => void;
}

export enum LinkedVendorsInputList {
  ExclusiveVendors = "exclusiveVendors",
  PreferredVendors = "preferredVendors",
}

export type VendorCardData = {
  searchId: string;
  id: string; //is used by sorting library
  images: string;
  title: string;
  description: string;
};

export type VendorSuggestion = {
  title: string;
  searchId: string;
};

function VendorsForm(_props: LinkedVendorsFormProps, ref: React.Ref<App.FormRef>) {
  const [exclusiveVendors, setExclusiveVendors] = useState<VendorCardData[]>([]);
  const [preferredVendors, setPreferredVendors] = useState<VendorCardData[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [vendorOptions, setVendorOptions] = useState<VendorSuggestion[]>([]);
  const [isVendorOptionsLoading, setIsVendorOptionsLoading] = useState(false);

  const { listingItemSaveHandler, listingItemData } = useContext(ListingManagementContext) || {};

  const { formik } = useLinkedVendorsForm({
    listingItemSaveHandler,
    listingItemData,
  });

  const handleAddVendor = async (
    _event: SyntheticEvent<Element, Event>,
    vendor: VendorSuggestion | null,
    setVendors: React.Dispatch<React.SetStateAction<VendorCardData[]>>,
  ) => {
    setSearchValue("");
    if (!vendor) {
      return;
    }

    const query = SearchLogic.defaultQuery(
      10,
      `search.in(searchId,'${vendor.searchId}','|')`,
      "images/imageHash, searchId, title, description",
    );
    const vendorResponse = await SearchServiceV2.postSearchQuery(query);
    if (!vendorResponse.body.payload.value?.[0]) {
      return;
    }

    const { title, description, searchId, images } = vendorResponse.body.payload.value?.[0];
    const vendorData: VendorCardData = {
      title,
      description,
      id: searchId,
      searchId,
      images,
    };

    setVendors((prev) => {
      const isDuplicate = prev.some((v) => v.searchId === vendorData.searchId);
      return isDuplicate ? prev : [...prev, vendorData];
    });
  };

  const fetchVendorSuggestions = useDebouncedCallback(async () => {
    if (searchValue.length <= 1) {
      setVendorOptions([]);
      return;
    }
    setIsVendorOptionsLoading(true);
    const addedVendorsIds = [...exclusiveVendors, ...preferredVendors].map(
      (vendor) => vendor.searchId,
    );
    let filter = "search.in(dataType,'vendor','|')";
    if (addedVendorsIds.length) {
      filter += ` and not search.in(searchId,'${addedVendorsIds.join("|")}','|')`;
    }
    const defaultSuggestion = SearchLogic.defaultSuggestions(searchValue, filter);

    const response = await SearchServiceV2.postSearchSuggestion(defaultSuggestion);

    if (!Array.isArray(response.body?.payload?.value)) {
      setVendorOptions([]);
      setIsVendorOptionsLoading(false);
      return;
    }

    const opts: { title: string; searchId: string }[] = [];
    const seenTitles = new Set<string>();

    for (const value of response.body.payload.value) {
      if (!seenTitles.has(value["@search.text"])) {
        opts.push({ title: value["@search.text"], searchId: value.searchId });
        seenTitles.add(value["@search.text"]);
      }
    }

    setVendorOptions(opts);
    setIsVendorOptionsLoading(false);
  }, 250);

  const handleAddExclusiveVendor = (
    event: SyntheticEvent<Element, Event>,
    value: VendorSuggestion | null,
  ) => handleAddVendor(event, value, setExclusiveVendors);

  const handleAddPreferredVendor = (
    event: SyntheticEvent<Element, Event>,
    value: VendorSuggestion | null,
  ) => handleAddVendor(event, value, setPreferredVendors);

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));

  useEffect(() => {
    fetchVendorSuggestions();
  }, [searchValue, exclusiveVendors, preferredVendors, fetchVendorSuggestions]);

  return (
    <div className={styles.vendorsForm}>
      <form onSubmit={formik.handleSubmit} data-testid="vendors-form">
        <VendorSection
          title="Exclusive Vendors"
          description="Exclusive vendors have an intimate knowledge of the space and are usually required to be involved with events."
          searchVendorValue={searchValue}
          setSearchVendorValue={setSearchValue}
          handleAddVendor={handleAddExclusiveVendor}
          vendors={exclusiveVendors}
          vendorOptions={vendorOptions}
          isVendorOptionsLoading={isVendorOptionsLoading}
          setVendors={setExclusiveVendors}
          inputId={`${LinkedVendorsInputList.ExclusiveVendors}-select`}
        />
        <VendorSection
          title="Preferred Vendors"
          description="Preferred vendors have been hand-picked to deliver exceptional service in the space. It is strongly recommended these vendors are used to ensure a seamless event production."
          searchVendorValue={searchValue}
          setSearchVendorValue={setSearchValue}
          handleAddVendor={handleAddPreferredVendor}
          vendorOptions={vendorOptions}
          isVendorOptionsLoading={isVendorOptionsLoading}
          vendors={preferredVendors}
          setVendors={setPreferredVendors}
          inputId={`${LinkedVendorsInputList.PreferredVendors}-select`}
        />
      </form>
    </div>
  );
}

export default forwardRef(VendorsForm);
