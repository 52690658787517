import React, { useContext, useEffect, useRef, useState } from "react";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { ListingCategories } from "@/src/const/listingManagament";
import { FormRef } from "@/src/type/app";
import { EntryPopup } from "./components/EntryPopup";
import { LinkedVendorsForm } from "./components/LinkedVendorsForm";

function LinkedVendorsContent() {
  const [isEntryPopupVisible, setIsEntryPopupVisible] = useState(false);
  const formRef = useRef<FormRef>(null);

  const { listingItemData } = useContext(ListingManagementContext) || {};

  const onSaveVendorsFormData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  useEffect(() => {
    if (!Array.isArray(listingItemData?.exclusiveVendors) && !Array.isArray(listingItemData?.preferredVendors)) {
      setIsEntryPopupVisible(true);
    } else {
      setIsEntryPopupVisible(false);
    }
  }, [listingItemData]);

  return (
    <CategoryContentWrapper
      category={ListingCategories.Vendors}
      onSaveFormData={onSaveVendorsFormData}
    >
      <LinkedVendorsForm ref={formRef} onSaveVendorsFormData={onSaveVendorsFormData} />
      <EntryPopup isOpen={isEntryPopupVisible} onClose={() => setIsEntryPopupVisible(false)} />
    </CategoryContentWrapper>
  );
}

export default LinkedVendorsContent;
