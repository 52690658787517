import { useMemo } from "react";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { ListingItemEditableData } from "@/src/component/view/ListingManagement/ListingManagement";
import { LinkedVendorsInputList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/LinkedVendorsForm/LinkedVendorsForm";

// interface VendorsSaveData {
//     vendors: string[];
// }

interface UseLinkedVendorsFormProps {
    listingItemData?: ListingItemEditableData;
    listingItemSaveHandler?: (data: ListingItemEditableData) => Promise<Record<string, string>>;
}

export interface VendorsFormType {
    [LinkedVendorsInputList.ExclusiveVendors]: string[];
    [LinkedVendorsInputList.PreferredVendors]: string[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useLinkedVendorsForm({ listingItemData, listingItemSaveHandler }: UseLinkedVendorsFormProps): {
    formik: FormikProps<VendorsFormType>;
} {
    const defaultExclusiveVendors: string[] = [];
    const defaultPreferredVendors: string[] = [];

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                [LinkedVendorsInputList.ExclusiveVendors]: Yup.array().of(Yup.string()),
                [LinkedVendorsInputList.PreferredVendors]: Yup.array().of(Yup.string())

            }),
        [],
    );

    const formik = useFormik<VendorsFormType>({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            [LinkedVendorsInputList.ExclusiveVendors]: defaultExclusiveVendors,
            [LinkedVendorsInputList.PreferredVendors]: defaultPreferredVendors,
        },
        validationSchema,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: async (values, { setFieldError, setFieldTouched }) => {
            // submit vendors
            // listingItemSaveHandler()
        },
    });

    return {
        formik,
    };
}
