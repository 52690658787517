import React, { useState } from "react";
import { BaseConfirmationModal, ReactSortableManager } from "@/src/component/base";
import { LinkedVendorCard } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/LinkedVendorCard";
import { VendorCardData } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/LinkedVendorsForm/LinkedVendorsForm";
import styles from "./LinkedVendorsList.module.scss";

interface LinkedVendorsListProps {
  vendors: VendorCardData[];
  setVendors: (vendors: VendorCardData[]) => void;
}

function LinkedVendorsList({ vendors, setVendors }: LinkedVendorsListProps) {
  const [vendorIdToDelete, setVendorIdToDelete] = useState("");

  const handleConfirmDelete = () => {
    setVendors(vendors.filter((v) => v.searchId !== vendorIdToDelete));
    setVendorIdToDelete("");
  };

  return (
    <>
      <ReactSortableManager<VendorCardData>
        list={vendors}
        markHasUnsavedData={() => {}}
        setList={setVendors}
        className={styles.vendorCardList}
      >
        {vendors.map((vendor) => (
          <LinkedVendorCard
            vendor={vendor}
            key={vendor.searchId}
            handleRemoveClick={() => setVendorIdToDelete(vendor.searchId)}
          />
        ))}
      </ReactSortableManager>
      <BaseConfirmationModal
        isOpen={!!vendorIdToDelete}
        handleClose={() => setVendorIdToDelete("")}
        handleConfirm={handleConfirmDelete}
        confirmationText="Are you sure you want to delete this vendor?"
      />
    </>
  );
}

export default LinkedVendorsList;
